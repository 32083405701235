import { createTheme, responsiveFontSizes, Theme } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CommonRoutes, ProtectedRoutes } from './Routes';
import { darkTheme } from './theme/appTheme';
import {
  APP_GOOGLE_ANALYTICS_ENABLED,
  APP_GOOGLE_ANALYTICS_TRACKING_ID,
  APP_GOOGLE_INDEXING_ENABLED,
  APP_HOTJAR_ENABLED,
  APP_HOTJAR_SITE_ID,
  APP_TITLE,
  ENV_NAME
} from './utils/constants';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import { LanguageProvider } from './store/languageContext/languageContext';
import RouteChangeTracker from './components/RouteChangeTracker';
import { MeasurementSystemProvider } from './store/MeasurementSystemContext/measurementSystemContext';

const HOTJAR_SNIPPET_VERSION = 6;


function App() {
  let theme: Theme = createTheme(darkTheme);
  theme = responsiveFontSizes(theme);

  useEffect(() => {
    if (APP_GOOGLE_ANALYTICS_ENABLED && APP_GOOGLE_ANALYTICS_TRACKING_ID) {
      ReactGA.initialize(APP_GOOGLE_ANALYTICS_TRACKING_ID, { gaOptions: { siteSpeedSampleRate: 100 } })
    }

    if (APP_HOTJAR_ENABLED && APP_HOTJAR_SITE_ID) {
      hotjar.initialize(Number(APP_HOTJAR_SITE_ID), HOTJAR_SNIPPET_VERSION);
    }
  }, [])


  return (
    <LanguageProvider>
      <MeasurementSystemProvider>
        <Helmet>
          <title>{APP_TITLE}</title>
          <meta name="description" content={`${ENV_NAME}Ladle Slag Model by RHI Magnesita`} />
          `${ APP_GOOGLE_INDEXING_ENABLED ? <meta name="robots" content="all"/> : <meta name="robots" content="noindex"/> }`
        </Helmet>
        <BrowserRouter>
          <Route component={() => <RouteChangeTracker />} />
          <Switch>
            <Route path="/" component={() => <CommonRoutes theme={theme} />} exact />
            <Route path="/app" component={() => <ProtectedRoutes theme={theme} />} />
          </Switch>
        </BrowserRouter>
      </MeasurementSystemProvider>
    </LanguageProvider>
  );
}

export default App;
